<template lang="pug">
VAppBar.the-app-bar(
  :dense="true"
  theme="dark"
  height=48
  extension-height=48
)
  VBtn.the-app-bar__skip-link(
    variant="text"
    href="#main"
  ) {{ i18n.t("Global.SkipToContent") }}
  slot
  template(
    v-if="$slots.extension"
    #extension
  )
    slot(name="extension")
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const i18n = useI18n();
</script>

<style lang="scss" scoped>
.the-app-bar.the-app-bar.the-app-bar.the-app-bar {
  backdrop-filter: blur(10px);
  background-color: rgba(var(--v-theme-primary-c900), 0.9);
}
.the-app-bar__skip-link.the-app-bar__skip-link {
  $width: 200px;
  $margin: 16px;
  width: $width;
  margin-inline-start: -$width - $margin;
  margin-inline-end: $margin;
  transition: margin-left 0.5s ease;
}

.the-app-bar__skip-link:focus {
  margin-left: 0;
}
</style>
