/**
 * Extra utilities for working with the `neverthrow` package.
 */

import { Result, ResultAsync } from "neverthrow";

/**
 * Convert a function like `(...args: A) => Promise<Result<T, E>>` into `(...args: A) => ResultAsync<T, E>`.
 *
 * Similarly to the warnings at https://github.com/supermacro/neverthrow#resultasyncfromsafepromise-static-class-method
 * you must ensure that `func` will never reject.
 *
 * See https://github.com/supermacro/neverthrow/issues/424
 */
export function wrapResultAsyncFunction<A extends any[], T, E>(
  func: (...args: A) => Promise<Result<T, E>>,
): (...args: A) => ResultAsync<T, E> {
  return (...args): ResultAsync<T, E> => new ResultAsync(func(...args));
}

/**
 * This is useful for passing around heterogeneous lists while preserving the type of each element.
 *
 * See https://github.com/supermacro/neverthrow#resultasynccombine-static-class-method
 */
export const tuple = <T extends any[]>(...args: T): T => args;

/**
 * Map the `Result<T1,E1>` inside `ResultAsync<T1,E1>` to any `Result<T2,E2>` and return a `ResultAsync<T2,E2>`.
 *
 * @param ra
 *   The ResultAsync to manipulate.
 * @param mapper
 *   A function that operates on the `Result<T1,E1>` inside `ResultAsync<T1,E1>` and returns a `Result<T2,E2>` or a
 *   `ResultAsync<T2,E2>`.
 */
export function mapResultAsync<T1, E1, T2, E2>(
  ra: ResultAsync<T1, E1>,
  mapper: (r: Result<T1, E1>) => Result<T2, E2> | ResultAsync<T2, E2>,
): ResultAsync<T2, E2> {
  return new ResultAsync((async () => mapper(await ra))());
}

/**
 * Combine a list of `ResultAsync<T1, E1>` into a `ResultAsync<T2[], E2>`, filtering out the ones that don't match the
 * predicate, and mapping the rest.
 *
 * @param resultAsyncList
 *   The list of `ResultAsync` to combine.
 * @param predicate
 *   This is called for each item to determine whether it should be included in the final result. It receives a Result
 *   object, and must return a boolean.
 * @param callback
 *   This is called for each item that matches the predicate. It receives a Result object, and may return a `Result` or
 *   a `ResultAsync`. It may also change both the `Ok` and the `Err` types if needed.
 */
export function filterMapCombineResultAsync<T1, E1, T2, E2>(
  resultAsyncList: ResultAsync<T1, E1>[],
  predicate: (r: Result<T1, E1>) => boolean,
  callback: (r: Result<T1, E1>) => Result<T2, E2> | ResultAsync<T2, E2>,
): ResultAsync<T2[], E2> {
  return new ResultAsync(
    (async () => {
      const resultList = await Promise.all(resultAsyncList);
      const filtered = resultList.filter((r) => predicate(r));
      const mapped = await Promise.all(filtered.map((r) => callback(r)));
      return Result.combine(mapped);
    })(),
  );
}

/**
 * Combine a list of ResultAsync<T, E> into a ResultAsync<T[], E>, filtering out the ones that don't match the
 * predicate.
 *
 * @param resultAsyncList
 *   See `filterMapCombineResultAsync`.
 * @param predicate
 *   See `filterMapCombineResultAsync`.
 */
export function filterCombineResultAsync<T, E>(
  resultAsyncList: ResultAsync<T, E>[],
  predicate: (r: Result<T, E>) => boolean,
): ResultAsync<T[], E> {
  return filterMapCombineResultAsync(resultAsyncList, predicate, (r) => r);
}

/**
 * Combine a list of `ResultAsync<T1, E1>` into a `ResultAsync<T2[], E2>` while mapping the results.
 *
 * @param resultAsyncList
 *   See `filterMapCombineResultAsync`.
 * @param callback
 *   See `filterMapCombineResultAsync`.
 */
export function mapCombineResultAsync<T1, E1, T2, E2>(
  resultAsyncList: ResultAsync<T1, E1>[],
  callback: (r: Result<T1, E1>) => Result<T2, E2> | ResultAsync<T2, E2>,
): ResultAsync<T2[], E2> {
  return filterMapCombineResultAsync(resultAsyncList, () => true, callback);
}
