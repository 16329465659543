import { computed, reactive, type Ref, ref, watch } from "vue";

export function useComputedPromise<T, E = any>(
  getter: () => Promise<T>,
): {
  readonly promise: Promise<T>;
  readonly state: {
    /**
     * Whether a new promise is pending (not resolved or rejected).
     */
    readonly isPending: boolean;

    /**
     * Whether the last promise has resolved or rejected.
     */
    readonly isSettled: boolean;

    /**
     * Whether the last promise has resolved.
     */
    readonly isResolved: boolean;

    /**
     * Whether the last promise has rejected.
     */
    readonly isRejected: boolean;

    /**
     * Whether the last promise has settled, but a new one is pending.
     */
    readonly isOutdated: boolean;

    /**
     * The last resolved value.
     */
    readonly value: T | undefined;

    /**
     * The last rejected error.
     */
    readonly error: E | undefined;
  };
} {
  const promise = computed(getter);

  const isPending = ref<boolean>(false);
  const isResolved = ref<boolean>(false);
  const isRejected = ref<boolean>(false);

  const isSettled = computed(() => isResolved.value || isRejected.value);
  const isOutdated = computed(() => isSettled.value && isPending.value);

  // From https://github.com/vuejs/core/issues/2136#issuecomment-693524663 :
  // "You need to cast to as Ref<T> ... if you sure that the type doesn't have any nested refs ..."
  const value = ref<T | undefined>(undefined) as Ref<T | undefined>;
  const error = ref<E | undefined>(undefined) as Ref<E | undefined>;

  watch(
    promise,
    (p) => {
      isPending.value = true;

      p.then(
        (v) => {
          if (p !== promise.value) {
            // This promise is outdated. Do nothing.
            return;
          }

          isResolved.value = true;
          isRejected.value = false;
          value.value = v;
          error.value = undefined;
        },
        (e: E) => {
          if (p !== promise.value) {
            // This promise is outdated. Do nothing.
            return;
          }

          isResolved.value = false;
          isRejected.value = true;
          value.value = undefined;
          error.value = e;
        },
      ).finally(() => {
        if (p !== promise.value) {
          // This promise is outdated. Do nothing.
          return;
        }

        isPending.value = false;
      });
    },
    { immediate: true },
  );

  return reactive({
    promise,
    state: {
      error,
      value,
      isResolved,
      isRejected,
      isPending,
      isSettled,
      isOutdated,
    },
  });
}
