<template lang="pug">
VTextField.verse-picker-text-field(
  ref="textField"
  v-model="inputModel"
  v-model:focused="focused"
  v-bind="$attrs"
  :disabled="disabled"
  :persistent-hint="true"
  :hint="errorMessage || hint || undefined"
  :label="label"
  :placeholder="i18n.t('VersePicker.PickAVerse')"
  :title="i18n.t('VersePicker.PickAVerse')"
  :loading="loading"
  :clearable="true"
  density="compact"
  @keyup.enter="enter"
)
  template(#prepend)
    VIcon(
      :class="status.classes"
      :title="status.text"
    ) {{ status.icon }}
</template>

<script setup lang="ts">
/**
 * Select verses from a Bible translation.
 */

import type {
  ScriptureQuery,
  ScriptureUtilMissingTranslationError,
  VidRange,
} from "@rsc/scripture-util";
import {
  interpretScriptureQuery,
  rangeOrNullDiffers,
  ScriptureUtilReferenceRenderError,
} from "@rsc/scripture-util";
import { useI18n } from "vue-i18n";
import { mdiAlertCircle, mdiBookCross, mdiLoading } from "@mdi/js";
import { computed, onMounted, ref, watch } from "vue";
import type { ScriptureClientError } from "@rsc/scripture-client";
import type { Err, Result } from "neverthrow";
import { ok, okAsync } from "neverthrow";
import { useComputedPromise } from "~/composables/useComputedPromise";
import { injectRequired } from "~/injectRequired";
import { mapResultAsync } from "~/errors/neverthrow";
import type { Dict } from "~/types";
import { ScriptureClientKey } from "~/injectionKeys";

const i18n = useI18n();

interface Props {
  /**
   * The selected VID range. The tid is always overwritten by the translation prop's tid.
   */
  range?: VidRange | null;

  /**
   * The ID of the translation from which to select verses.
   */
  versePickerTid: string;

  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;

  /**
   * True if the user is not allowed to modify the VID range.
   */
  disabled?: boolean;

  /**
   * True if the input element should show a loading animation.
   */
  loading?: boolean;

  label?: string;
}

const props = withDefaults(defineProps<Props>(), {});

interface Emits {
  /**
   * Emitted when the user selects a new VID range.
   */
  (e: "update:range", range: VidRange | null): void;

  /**
   * Emitted when the user presses the Enter key.
   */
  (e: "submit"): void;

  /**
   * Emitted when the user types something. This can be used to provide suggestions.
   */
  (e: "update:query", query: ScriptureQuery): void;
}

const emit = defineEmits<Emits>();

const scriptureClient = injectRequired(ScriptureClientKey);

/**
 * A model for the text field containing the user-provided or rendered verse reference.
 */
const inputModel = ref<string>("");

/**
 * True when the text field has focus, and false when it is blurred.
 */
const focused = ref<boolean>(false);

/**
 * The text field in the template.
 */
const textField = ref<HTMLInputElement | null>(null);

const interpreted = useComputedPromise<ScriptureQuery>(() =>
  interpretScriptureQuery(
    scriptureClient,
    props.versePickerTid,
    props.versePickerTid,
    (inputModel.value || "").trim(),
  ),
);

/**
 * Computed getter/setter bound to the range prop.
 */
const range = computed<VidRange | null>({
  get() {
    return props.range?.changeTranslation(props.versePickerTid) || null;
  },
  set(newRange) {
    const range = newRange?.changeTranslation(props.versePickerTid) || null;

    if (!rangeOrNullDiffers(range, props.range)) {
      // No need to emit, since nothing actually changed.
      return;
    }

    // Update the prop.
    emit("update:range", range);
  },
});

const formattedRange = useComputedResultAsync<
  string,
  | ScriptureClientError
  | ScriptureUtilReferenceRenderError
  | ScriptureUtilMissingTranslationError
>(() => {
  const client = scriptureClient;
  const rng = range.value;

  if (!rng || !rng.tid) {
    return okAsync("");
  }

  const renderedResult = rng.renderReference(
    client,
    // In this case, the tid in the given range should be the same as the tid of the verse picker.
    rng.tid,
    false,
    true,
  );

  return mapResultAsync<
    string,
    | ScriptureClientError
    | ScriptureUtilReferenceRenderError
    | ScriptureUtilMissingTranslationError,
    string,
    ScriptureClientError | ScriptureUtilMissingTranslationError
  >(renderedResult, (res) => {
    if (
      res.isErr() &&
      (res as Err<unknown, Error>).error instanceof
        ScriptureUtilReferenceRenderError
    ) {
      // The range is not valid in the selected translation. This is not an error. Just return an empty string.
      return ok("");
    }
    return res as Result<
      string,
      ScriptureClientError | ScriptureUtilMissingTranslationError
    >;
  });
});

/**
 * Replace the user input with the formatted verse range.
 */
async function replaceUserInput() {
  if (focused.value) {
    // The user is probably still typing.
    return;
  }

  // Wait for `formattedRange` to be ready.
  const result = await formattedRange.resultAsync;
  if (result.isOk()) {
    inputModel.value = result.value;
  }
}

/**
 * Handle keyboard ENTER press in the text field.
 */
async function enter() {
  const query = await interpreted.promise;

  // Blur. This will eventually emit, but not soon enough, so we hasten the processing by updating `range` directly.
  await textField.value?.blur();
  range.value = query.range;

  // Also notify the parent that the user pressed enter, in case it needs to do something extra like close a dialog.
  emit("submit");
}

onMounted(async () => {
  await replaceUserInput();
});

const errorMessage = computed<string>(() => {
  if (interpreted.state.error) {
    return interpreted.state.error?.message || "Unknown error";
  }

  if (interpreted.state.value?.error) {
    return interpreted.state.value.error;
  }

  if (formattedRange.error) {
    return formattedRange.error.message;
  }

  return "";
});

const hint = computed<string>(() => {
  return interpreted.state.value?.hint || "";
});

/**
 * Properties for the status icon.
 */
const status = computed<{
  text: string;
  classes: Dict<boolean>;
  icon: string;
}>(() => {
  if (props.loading) {
    return {
      text: "",
      classes: {
        "mdi-spin": true,
      },
      icon: mdiLoading,
    };
  }

  if (errorMessage.value) {
    return {
      text: errorMessage.value,
      classes: {},
      icon: mdiAlertCircle,
    };
  }

  return {
    text: "",
    classes: {},
    icon: mdiBookCross,
  };
});

watch(range, async (to, from) => {
  if (!rangeOrNullDiffers(to, from)) {
    return;
  }

  await replaceUserInput();
});

watch(
  () => props.versePickerTid,
  async (to, from) => {
    if (to === from) {
      // Nothing really changed.
      return;
    }

    await replaceUserInput();
  },
);

watch(focused, (to, from) => {
  if (from && !to) {
    // Blurred!
    if (interpreted.state.value) {
      range.value = interpreted.state.value.range;
    }
  }
});

watch(
  () => interpreted.state.value,
  (to) => {
    // Inform the parent that the query changed.
    // This is emitted on every keystroke.
    // This can be used to provide suggestions.
    if (to) {
      emit("update:query", to);
    }
  },
);
</script>
