<template lang="pug">
VListItem(@click="$emit('choose', range.changeTranslation(versePickerTid))")
  VListItemTitle {{ rendered.isPending ? "…" : rendered.value }}
</template>

<script setup lang="ts">
import type { VidRange } from "@rsc/scripture-util";
import { injectRequired } from "~/injectRequired";
import { ScriptureClientKey } from "~/injectionKeys";

interface Props {
  /**
   * The ID of the translation currently being used in the verse picker.
   */
  versePickerTid: string;

  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;

  /**
   * The history item.
   */
  range: VidRange;
}

const props = withDefaults(defineProps<Props>(), {});

interface Emits {
  /**
   * Emitted when the user selects a new VidRange.
   */
  (e: "choose", value: VidRange): void;
}

const emit = defineEmits<Emits>();

const scriptureClient = injectRequired(ScriptureClientKey);

const rendered = useComputedResultAsync(() =>
  props.range
    .changeTranslation(props.versePickerTid)
    .renderReference(scriptureClient, props.localTid, false, true),
);
</script>
